import React, { Suspense, useEffect } from 'react';
import {
	RootState,
	fetchVehicleCategories,
	store,
} from '../../redux';
import { Hero, Section } from './components';
import atvWater from '../../assets/atv-water-track.png';
import {
	CanAmSubscription,
	CategoryCardsSkeleton,
	Promotion,
} from '../../components';
import { useSelector } from 'react-redux';
import { YouMayLike } from '../shop/components';
import { useTranslation } from '../../helpers';
const Home = () => {
	const translations = useTranslation();

	const vehicleDetails = useSelector(
		(state: RootState) => state.generic.vehicles,
	);
	const vehicleFetchStatus = vehicleDetails.status;
	const vehicleCategories = vehicleDetails.parentCategories;
	useEffect(() => {
		if (
			!vehicleCategories ||
			(vehicleCategories && vehicleCategories.length === 0)
		) {
			store.dispatch(fetchVehicleCategories());
		}
	}, []);
	const DATE_PROMOTION_CAN_BE_SHOWN_UNTIL = '2024-12-01';
	const canPromotionBeShown =
		new Date(Date.now()) <
		new Date(DATE_PROMOTION_CAN_BE_SHOWN_UNTIL);
	return (
		<Suspense fallback="Loading...">
			<Hero
				imagePosition="right"
				imgSrc={atvWater}
				text={translations.text.home_hero}
			/>
			{vehicleFetchStatus === 'fulfilled' ? (
				<Section
					payload={vehicleCategories}
					verticalLineColor="#ffc72c"
					headingTitle={translations.text.our_categories}
					ctaText={translations.buttons.view_products}
					subTitle=""
					hasCta={true}
					path="/shop/vehicles"
				/>
			) : (
				<CategoryCardsSkeleton />
			)}
			<YouMayLike category={null} />
			<CanAmSubscription />
			{canPromotionBeShown ? <Promotion /> : null}
			{/* <InfoHero
				title="Insights"
				subTitle="Pellentesque ultrices ullamcorper sed tincidunt ac ultrices. Enim venenatis tortor pharetra velit ac. Sed."
				sections={
					<Grid
						container
						maxWidth="lg"
						sx={{ my: 8 }}
						// sx={{ textAlign: 'center' }}
						// sx={{ flexWrap: 'wrap', flexDirection: 'row' }}
					>
						<Grid item xs={12} md={4}>
							<section
								style={{
									width: 'max-content',
									margin: '0 auto',
									// textAlign: 'left',
								}}
							>
								<Typography
									variant="h2"
									// className={styles.subHeading}
									noWrap
									component="h4"
								>
									1000
									<sup>+</sup>
								</Typography>
								<Typography
									variant="body1"
									// className={styles.subHeading}
									noWrap
									component="p"
								>
									sold items
								</Typography>
							</section>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							sx={{
								borderRight: `1px solid ${colors['brp-white'].main}`,
								borderLeft: `1px solid ${colors['brp-white'].main}`,
							}}
						>
							<section
								style={{
									width: 'max-content',
									margin: '0 auto',
									// textAlign: 'left',
								}}
							>
								<Typography
									variant="h2"
									// className={styles.subHeading}
									noWrap
									component="h4"
								>
									100
									<sup>%</sup>
								</Typography>
								<Typography
									variant="body1"
									// className={styles.subHeading}
									noWrap
									component="p"
								>
									successful delivery rate
								</Typography>
							</section>
						</Grid>
						<Grid item xs={12} md={4}>
							<section
								style={{
									width: 'max-content',
									margin: '0 auto',
									// textAlign: 'left',
								}}
							>
								<Typography
									variant="h2"
									// className={styles.subHeading}
									noWrap
									component="h4"
								>
									5<sup>*</sup>
								</Typography>
								<Typography
									variant="body1"
									// className={styles.subHeading}
									noWrap
									component="p"
								>
									customer satisfaction
								</Typography>
							</section>
						</Grid>
					</Grid>
				}
			/> */}
			{/* todo: because of absolute pos, text can overlap cards */}
			{/* <Section
				payload={productCategories}
				verticalLineColor="#ffc72c"
				headingTitle="Our services"
				subTitle="Pellentesque ultrices ullamcorper sed tincidunt ac ultrices. Enim venenatis tortor pharetra velit ac. Sed."
				ctaText="View categories"
				hasCta={false}
			/>
			<Hero imagePosition="right" imgSrc={atvDust} /> */}
			{/* todo: resolve this */}
			{/* <Container
				maxWidth="lg"
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					flexDirection: 'row',
					justifyContent: 'space-between',
					my: 8,
				}}
			>
				<ProductCard product={products[0]} />
				<ProductCard product={products[1]} />
				<ProductCard product={products[2]} />
			</Container> */}
			{/* todo: this below should be somewhere else */}
			{/* <Hero imagePosition="left" imgSrc={skiDoo} text="" /> */}
			{/* todo: this below is temporarily disabled */}
			{/* <SeaDooSubscription /> */}
		</Suspense>
	);
};

export default Home;
