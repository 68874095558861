import React, { useState } from 'react';
import {
	Dialog,
	Fab,
	Button,
	MobileStepper,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimes,
	faBullhorn,
} from '@fortawesome/free-solid-svg-icons';
import blackFridayPromotion from '../../assets/blackFridayPromotion.jpg';
import {
	KeyboardArrowLeft,
	KeyboardArrowRight,
} from '@mui/icons-material';
import {
	RootState,
	store,
	updatePromoPopover,
} from '../../redux';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../helpers';

export const Promotion = () => {
	const translations = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);
	const isPromoPopoverOpen = useSelector(
		(state: RootState) => state.generic.promoPopover.isOpen,
	);
	const handleFabClick = () =>
		store.dispatch(updatePromoPopover(!isPromoPopoverOpen));

	const handlePromoClick = async () => {
		// can-am on road - hard coded from live lmao
		navigate(`/shop/products?category=13`);
	};

	const promotions = [blackFridayPromotion];

	const [activeStep, setActiveStep] = useState(0);
	const maxSteps = promotions.length;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	return (
		<>
			<Dialog
				open={isPromoPopoverOpen}
				onClose={handleFabClick}
				PaperProps={{
					style: {
						margin: isSmallScreen ? '16px' : '64px',
						backgroundColor: 'black',
						height: isSmallScreen ? 'auto' : '720px',
					},
				}}
			>
				<div
					style={{
						margin: 0,
						padding: 0,
						height: 'max-content',
						maxHeight: 720,
						cursor: 'pointer',
					}}
				>
					<img
						src={promotions[activeStep]}
						alt={`Promotional offer ${activeStep + 1}`}
						// style={{ height: 'calc(100% - 60px)' }}
						style={{
							height: '100%',
							maxHeight: '100vh',
							width: '100%',
							objectFit: 'contain',
						}}
						onClick={handlePromoClick}
					/>
					{promotions.length > 1 ? (
						<MobileStepper
							steps={maxSteps}
							position="static"
							activeStep={activeStep}
							nextButton={
								<Button
									size="small"
									onClick={handleNext}
									disabled={activeStep === maxSteps - 1}
								>
									{translations.buttons.next}
									<KeyboardArrowRight
										sx={{ marginBottom: '4px' }}
									/>
								</Button>
							}
							backButton={
								<Button
									size="small"
									onClick={handleBack}
									disabled={activeStep === 0}
								>
									<KeyboardArrowLeft
										sx={{ marginBottom: '4px' }}
									/>
									{translations.buttons.back}
								</Button>
							}
						/>
					) : null}
				</div>
			</Dialog>
			<Fab
				color="primary"
				sx={{
					position: 'fixed',
					bottom: '16px',
					left: '16px',
				}}
				onClick={handleFabClick}
			>
				<FontAwesomeIcon
					icon={isPromoPopoverOpen ? faTimes : faBullhorn}
				/>
			</Fab>
		</>
	);
};
